html {
  min-height: 100%;
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}

.renderer-core-form-flow__wrapper{
  background-color: red !important;
  height: unset !important;
  flex-grow: 1;

}

.renderer-components-default-thankyou-screen{
  pointer-events: unset !important;
}

.renderer-components-field-wrapper__content-wrapper {
  width: unset !important;
}